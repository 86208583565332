import CounselingList from "components/Counseling/CounselingList/CounselingListIndex";
import ReactECharts from "echarts-for-react";
import FeatherIcon from "feather-icons-react";
import useCounselingList from "hooks/counseling/useCounselingList";
import { useAppContext } from "providers/AppProvider";
import { Col, Row, Spinner } from "react-bootstrap";
import { Area, AreaChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import "./ComparativeAnalysisStyle.css";

const ComparativeAnalysis = () => {
    // 상담내역 임시로 값 밖아서
    // const patientUID = sessionStorage.getItem("patientUUID");
    const patientUID = "HCD-5b837a6640-f3b03ad7c4-9f4ed909";
    const programId = 40;
    const cycleId = 253;
    const counselingType = "ett"; // 상담내역 모듈별 구분
    const { data: counselingList, isLoading: isCounselingLoading, refetch } = useCounselingList(patientUID, programId, counselingType, cycleId); // 상담 내역 조회

    // 테마 정보를 Context로부터 직접 받음
    const {
        config: { theme },
    } = useAppContext();

    // 차트 데이터
    const data = [
        { name: "0주", 예측대사당량: 10, 나의대사량: 12 },
        { name: "1주", 예측대사당량: 45, 나의대사량: 20 },
        { name: "2주", 예측대사당량: 40, 나의대사량: 45 },
        { name: "3주", 예측대사당량: 35, 나의대사량: 40 },
    ];

    const timelineData = [
        { week: 3, date: "2024-05-22", metabolicRate: 50, oxygenUptake: -20 },
        { week: 6, date: "2024-06-12", metabolicRate: -33, oxygenUptake: 50 },
        { week: 12, date: "2024-07-03", metabolicRate: 50, oxygenUptake: 66 },
    ];

    const option = {
        tooltip: {
            trigger: "axis",
            axisPointer: {
                type: "shadow",
            },
            formatter: function (params) {
                let result = [`${params[0].axisValueLabel}`]; // 주차 정보 표시
                for (let i = 0; i < params.length / 2; i++) {
                    const before = Math.abs(params[i * 2].value);
                    const after = Math.abs(params[i * 2 + 1].value);
                    // 이후 데이터가 감소했을 경우 빨간색으로 표시
                    const afterText = after < before ? `<span style="color: #ff0000">${after}</span>` : after;
                    result.push(`${params[i * 2].marker}${params[i * 2].seriesName}: ${before} → ${afterText}`);
                }
                return result.join("<br/>");
            },
        },

        graphic: [
            {
                type: "text",
                left: "25%",
                top: "5%",
                style: {
                    text: "이전",
                    textAlign: "left",
                    fill: theme === "dark" ? "#9fa6bc" : "#000000",
                    fontWeight: "bold",
                    fontSize: 16,
                },
            },
            {
                type: "text",
                left: "48%",
                top: "5%",
                style: {
                    text: "기준일",
                    textAlign: "center",
                    fill: theme === "dark" ? "#9fa6bc" : "#000000",
                },
            },
            {
                type: "text",
                right: "25%",
                top: "5%",
                style: {
                    text: "이후",
                    textAlign: "right",
                    fill: theme === "dark" ? "#9fa6bc" : "#000000",
                    fontWeight: "bold",
                    fontSize: 16,
                },
            },
        ],
        legend: {
            data: [
                {
                    name: "예측 대사당량",
                    itemStyle: {
                        color: "#f4a261", // 주황색
                    },
                    textStyle: {
                        color: theme === "dark" ? "#9fa6bc" : "#000000",
                    },
                },
                {
                    name: "대사당량",
                    itemStyle: {
                        color: "#2a9d8f", // 초록색
                    },
                    textStyle: {
                        color: theme === "dark" ? "#9fa6bc" : "#000000",
                    },
                },
                {
                    name: "최대산소 섭취량",
                    itemStyle: {
                        color: "#4dabf7", // 파란색
                    },
                    textStyle: {
                        color: theme === "dark" ? "#9fa6bc" : "#000000",
                    },
                },
            ],
            right: "1%",
            top: "-1%",
            icon: "circle",
        },
        grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
        },
        xAxis: {
            type: "value",
            min: -100,
            max: 100,
            interval: 20,
            axisLabel: {
                formatter: function (value) {
                    return Math.abs(value); // 절대값으로 표시
                },
            },
        },
        yAxis: {
            type: "category",
            axisTick: {
                show: false,
            },
            axisLabel: {
                show: false,
            },
            data: ["12주차", "6주차", "3주차"],
        },
        series: [
            {
                name: "예측 대사당량",
                type: "bar",
                stack: "total",
                label: {
                    show: false,
                },
                data: [
                    // 이전 데이터
                    {
                        value: -80,
                        itemStyle: {
                            color: "#f4a261",
                        },
                    },
                    {
                        value: -100,
                        itemStyle: {
                            color: "#f4a261",
                        },
                    },
                    {
                        value: -60,
                        itemStyle: {
                            color: "#f4a261",
                        },
                    },
                ],
            },
            {
                name: "예측 대사당량",
                type: "bar",
                stack: "total",
                label: {
                    show: false,
                },
                data: [
                    // 이후 데이터
                    {
                        value: 70,
                        itemStyle: {
                            color: "#f4a261",
                            opacity: 0.5,
                        },
                    },
                    {
                        value: 60,
                        itemStyle: {
                            color: "#f4a261",
                            opacity: 0.5,
                        },
                    },
                    {
                        value: 70,
                        itemStyle: {
                            color: "#f4a261",
                        },
                    },
                ],
            },
            {
                name: "대사당량",
                type: "bar",
                stack: "total2",
                label: {
                    show: false,
                },
                // data: [-60, -50, -70].map((value) => ({
                //     value: value,
                //     itemStyle: {
                //         color: "#2a9d8f",
                //         opacity: 0.5,
                //     },
                // })),
                // 이전 데이터
                data: [
                    {
                        value: -30,
                        itemStyle: {
                            color: "#2a9d8f",
                        },
                    },
                    {
                        value: -40,
                        itemStyle: {
                            color: "#2a9d8f",
                        },
                    },
                    {
                        value: -40,
                        itemStyle: {
                            color: "#2a9d8f",
                        },
                    },
                ],
            },
            {
                name: "대사당량",
                type: "bar",
                stack: "total2",
                label: {
                    show: false,
                },
                // data: [60, 50, 70].map((value) => ({
                //     value: value,
                //     itemStyle: {
                //         color: "#2a9d8f",
                //     },
                // })),
                // 이후 데이터
                data: [
                    {
                        value: 50,
                        itemStyle: {
                            color: "#2a9d8f",
                        },
                    },
                    {
                        value: 30,
                        itemStyle: {
                            color: "#2a9d8f",
                            opacity: 0.5,
                        },
                    },
                    {
                        value: 50,
                        itemStyle: {
                            color: "#2a9d8f",
                        },
                    },
                ],
            },
            {
                name: "최대산소 섭취량",
                type: "bar",
                stack: "total3",
                label: {
                    show: false,
                },
                // data: [-35, -25, -45].map((value) => ({
                //     value: value,
                //     itemStyle: {
                //         color: "#4dabf7",
                //         opacity: 0.5,
                //     },
                // })),
                // 이전 데이터
                data: [
                    {
                        value: -50,
                        itemStyle: {
                            color: "#4dabf7",
                        },
                    },
                    {
                        value: -50,
                        itemStyle: {
                            color: "#4dabf7",
                        },
                    },
                    {
                        value: -50,
                        itemStyle: {
                            color: "#4dabf7",
                        },
                    },
                ],
            },
            {
                name: "최대산소 섭취량",
                type: "bar",
                stack: "total3",
                label: {
                    show: false,
                },
                // data: [35, 25, 45].map((value) => ({
                //     value: value,
                //     itemStyle: {
                //         color: "#4dabf7",
                //     },
                // })),
                // 이후 데이터
                data: [
                    {
                        value: 60,
                        itemStyle: {
                            color: "#4dabf7",
                        },
                    },
                    {
                        value: 30,
                        itemStyle: {
                            color: "#4dabf7",
                            opacity: 0.5,
                        },
                    },
                    {
                        value: 50,
                        itemStyle: {
                            color: "#4dabf7",
                        },
                    },
                ],
            },
        ],
    };

    const renderTimeline = () => (
        <div className="timeline">
            {timelineData.map((item, index) => (
                <Row key={index} className={`timeline-item ${index === timelineData.length - 1 ? "last-item" : ""}`}>
                    <Col sm={8} className="metric mt-2">
                        <Col sm={6}>
                            <Row className="justify-content-md-end">대사당량</Row>
                            <Row className="justify-content-md-end">최대산소 섭취량</Row>
                        </Col>
                        <Col sm={6} className="ms-2">
                            <Row>
                                <span className={`pe-0 metric-value ${item.metabolicRate >= 0 ? "increase" : "decrease"}`}>
                                    {item.metabolicRate >= 0 ? <FeatherIcon icon="arrow-up" /> : <FeatherIcon icon="arrow-down" />}
                                    {Math.abs(item.metabolicRate)}% {item.metabolicRate >= 0 ? "증가" : "감소"}
                                </span>
                            </Row>
                            <Row>
                                <span className={`pe-0 metric-value ${item.oxygenUptake >= 0 ? "increase" : "decrease"}`}>
                                    {item.oxygenUptake >= 0 ? <FeatherIcon icon="arrow-up" /> : <FeatherIcon icon="arrow-down" />}
                                    {Math.abs(item.oxygenUptake)}% {item.oxygenUptake >= 0 ? "증가" : "감소"}
                                </span>
                            </Row>
                        </Col>
                    </Col>
                    <Col sm={4} className="metric mt-3 ps-0">
                        <Col>
                            <Row className="justify-content-md-end pe-4">
                                <span className="fs-7">{item.week}주</span>
                            </Row>
                            <Row className="justify-content-md-end pe-4">
                                <p className="pe-1 fs-9 text-body-quaternary">({item.date})</p>
                            </Row>
                        </Col>
                    </Col>
                </Row>
            ))}
        </div>
    );

    return (
        <>
            <div className="mt-5">
                <h1 className="mb-2">운동량 비교분석</h1>
                <ResponsiveContainer width="100%" height={300}>
                    <AreaChart data={data}>
                        <defs>
                            <linearGradient id="gradientPredicted" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="0%" stopColor="rgba(190,29,149,1)" stopOpacity={1} />
                                <stop offset="100%" stopColor="rgba(190,29,149,0)" stopOpacity={0} />
                            </linearGradient>
                            <linearGradient id="gradientMy" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="0%" stopColor="rgba(53,57,99,1)" stopOpacity={1} />
                                <stop offset="100%" stopColor="rgba(53,57,99,0)" stopOpacity={0} />
                            </linearGradient>
                        </defs>
                        <CartesianGrid strokeDasharray="3 3" vertical={false} />
                        <XAxis dataKey="name" stroke="dotted" />
                        <YAxis stroke="none" />
                        <Tooltip />
                        <Legend verticalAlign="top" align="right" />
                        <Area
                            type="monotone"
                            dataKey="예측대사당량"
                            stroke="#BE1D95"
                            strokeWidth={2}
                            fill="url(#gradientPredicted)"
                            name="예측대사당량"
                        />
                        <Area type="monotone" dataKey="나의대사량" stroke="#353963" strokeWidth={2} fill="url(#gradientMy)" name="나의대사량" />
                    </AreaChart>
                </ResponsiveContainer>

                <Row className="justify-content-md-start p-2">
                    <Col sm={3}>{renderTimeline()}</Col>
                    <Col sm={9} className="w-90">
                        <ReactECharts option={option} style={{ height: "100%" }} opts={{ renderer: "svg" }} />
                    </Col>
                </Row>
            </div>

            <h1 className="mb-2 mt-5">상담</h1>

            {isCounselingLoading ? (
                <div className="d-flex justify-content-center align-items-center" style={{ height: "200px" }}>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </div>
            ) : counselingList?.data.counsel && counselingList?.data.counsel.length > 0 ? (
                <CounselingList data={counselingList?.data} type="patient" />
            ) : (
                <div className="order-1 order-xl-0 col-xl-12 col-12">
                    <div className="mb-4 shadow-none border overflow-visible card">
                        <div className="p-0 card-body">
                            <div className="p-15 text-center" style={{ height: "250px" }}>
                                <p className="mb-0 text-body-tertiary">
                                    <b>상담내역이 없습니다.</b>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ComparativeAnalysis;
