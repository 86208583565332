import { useMutation } from "@tanstack/react-query";
import fetchData from "apis/fetchData";
import EvaluationResultContainer from "components/EvaluationResult/EvaluationResultContainer";
import AnalysisResultContainer from "components/AI/Analysis/AnalysisResultContainer";
import ETTContainer from "components/WorkoutResult/ETTContainer";
import WorkoutResultContainer from "components/WorkoutResult/WorkoutResultContainer";
import { useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { currentDuration } from "store/atoms";
import { Container, Row, Col, Button, Nav } from "react-bootstrap";
import PatientInfoBox from "./PatientInfoBox";

const MonitoringDetailContents = ({ searchDate }) => {
    const patientUUID = sessionStorage.getItem("patientUUID");
    const duration = useRecoilValue(currentDuration);
    const [tab, setTab] = useState("workout");

    const { mutate: workoutMutate, data: workoutResult } = useMutation({
        mutationFn: (duration) => fetchData(`/v1/monitoring/workout/${duration}`, { patientUUID, searchDate }),
    });

    const { data: evaluationResult, mutate: evaluationMutate } = useMutation({
        mutationFn: () => fetchData("/v1/monitoring/evaluation/day", { patientUUID, searchDate }),
    });

    useEffect(() => {
        if (tab === "workout") workoutMutate(duration);
        else if (tab === "workout") evaluationMutate();
        else if (tab === "ai_anlys") console.log("API Server AI Data Request");
        // eslint-disable-next-line
    }, [searchDate]);

    return (
        workoutResult && (
            <Row className="g-4">
                <Col md={3}>
                    <PatientInfoBox patientInfo={workoutResult.data.patientInfo} />
                    <ETTContainer dropdown={workoutResult.data.dropdown} ettInfo={workoutResult.data.ettInfo} />
                </Col>
                <Col md={9}>
                    <Nav variant="tabs" defaultActiveKey="workout">
                        <Nav.Item>
                            <Nav.Link
                                active={tab !== "workout"}
                                onClick={() => {
                                    if (tab !== "workout") {
                                        setTab("workout");
                                        workoutMutate(duration);
                                    }
                                }}
                            >
                                운동기록
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link
                                active={tab !== "evaluation"}
                                onClick={() => {
                                    if (tab !== "evaluation") {
                                        setTab("evaluation");
                                        evaluationMutate();
                                    }
                                }}
                            >
                                평가기록
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link
                                active={tab !== "ai_anlys"}
                                onClick={() => {
                                    if (tab !== "ai_anlys") {
                                        setTab("ai_anlys");
                                        // evaluationMutate();
                                    }
                                }}
                            >
                                AI 분석결과
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                    {tab === "workout" ? (
                        <WorkoutResultContainer workoutResult={workoutResult.data} workoutMutate={workoutMutate} />
                    ) : tab === "evaluation" ? (
                        <EvaluationResultContainer evaluationResult={evaluationResult} />
                    ) : tab === "ai_anlys" ? (
                        <AnalysisResultContainer />
                    ) : (
                        <div>잘못된 요청입니다.</div>
                    )}
                </Col>
            </Row>
        )
    );
};

export default MonitoringDetailContents;
