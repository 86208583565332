import { useState } from "react";
import { Button, Form } from "react-bootstrap";
import useCounselingForm from "../../../hooks/counseling/useCounselingForm";

const CounselingModalForm = ({ handleClose, targetId, programId, type, etc }) => {
    const [comments, setContent] = useState("");
    const { mutate: submitCounseling, isPending } = useCounselingForm();

    const handleSubmit = (e) => {
        e.preventDefault();
        submitCounseling(
            { targetId, programId, type, comments, etc },
            {
                onSuccess: () => {
                    handleClose();
                },
            },
        );
    };

    return (
        <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
                <Form.Control
                    as="textarea"
                    rows={15}
                    value={comments}
                    onChange={(e) => setContent(e.target.value)}
                    placeholder="입력은 최대 1,000자까지 가능합니다."
                    maxLength={1000}
                />
            </Form.Group>
            <div className="d-flex justify-content-end">
                <Button variant="secondary" onClick={handleClose} className="me-2">
                    취소
                </Button>
                <Button variant="primary" type="submit" disabled={isPending || !comments.trim()}>
                    저장
                </Button>
            </div>
        </Form>
    );
};

export default CounselingModalForm;
