import { useMutation } from "@tanstack/react-query";
import fetchData from "apis/fetchData";
import { Modal, Button, ListGroup, Row, Col, Form } from "react-bootstrap";
import { useState } from "react";
import RadioButton from "components/common/RadioButton"; // assuming you already converted RadioButton to use bootstrap

const ExistingStaffListModal = ({ isModalOpen, setIsModalOpen, existingStaffList, operatorUUID, operatorDetailMutate }) => {
    const [isSelectedStaffUUID, setIsSelectedStaffUUID] = useState("");

    const { mutate } = useMutation({
        mutationFn: (requestData) => fetchData("/v1/setting/operator/staff/add", requestData),
        onSuccess: () => {
            setIsModalOpen(false);
            operatorDetailMutate({ operatorUUID });
        },
    });

    const handleClickRegist = () => {
        mutate({ operatorUUID, staffUUID: isSelectedStaffUUID });
    };

    return (
        <Modal show={isModalOpen} onHide={() => setIsModalOpen(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title>실무자</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ul>
                    <li>기존 실무자 정보를 조회하여 등록합니다.</li>
                    <li>새로운 실무자 등록은 “신규 실무자 등록하기"를 클릭하시거나 실무자 관리를 통해 등록해 주시기 바랍니다.</li>
                </ul>
                <ListGroup className="mb-3 overflow-y-auto overflow-x-hidden" style={{ maxHeight: "150px" }}>
                    {existingStaffList && (
                        <Row className="ms-4">
                            {existingStaffList.map((staff) => (
                                <Col xs={6} key={staff.no}>
                                    <Form.Check
                                        type="radio"
                                        className="fs-7"
                                        id={"rd_" + staff.UUID}
                                        label={staff.name}
                                        // checked={staff.UUID === isSelectedStaffUUID}
                                        onChange={() => setIsSelectedStaffUUID(staff.UUID)}
                                        disabled={!staff.enable}
                                        name="staffSelection"
                                    />
                                </Col>
                            ))}
                        </Row>
                    )}
                </ListGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setIsModalOpen(false)}>
                    취소
                </Button>
                <Button variant="primary" onClick={handleClickRegist}>
                    등록하기
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ExistingStaffListModal;
