import { useMutation } from "@tanstack/react-query";
import fetchData from "apis/fetchData";
import OperatorListTable from "components/OperatorSetting/OperatorListTable";
import HospitalInfoTable from "components/Setting/HospitalInfoTable";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "sonner";

const OperatorSetting = () => {
    const navigate = useNavigate();
    const [role, setRole] = useState(null);
    const [uuid, setUuid] = useState(null);

    const token = sessionStorage.getItem("token");

    useEffect(() => {
        if (token) {
            const decoded = jwtDecode(token);
            setRole(decoded.role);
            setUuid(decoded.uuid);
        }
    }, [token]);

    const { data: operatorInfo, mutate: operatorInfoMutate } = useMutation({
        mutationFn: () => fetchData("/v1/setting/operator/list"),
    });

    // 운영자와 관리자가 보는 화면 분기처리
    useEffect(() => {
        if (role === 1) navigate(`/operator-setting/detail?id=${uuid}`);
        else if (role === 0) operatorInfoMutate();
    }, [role, uuid, operatorInfoMutate, navigate]);

    return (
        <div className="pb-5">
            <Row className="g-4">
                <div className="mb-2">
                    <h2>운영자 관리</h2>
                </div>
                <hr className="bg-body-secondary mb-3" />
            </Row>

            <div className="order-1 order-xl-0 col-xl-12 col-12">
                <div className="mb-4 shadow-none border overflow-visible card">
                    <div className="p-0 card-body">
                        <div className="p-4">
                            {operatorInfo && (
                                <>
                                    <HospitalInfoTable hospitalInfo={operatorInfo.data.hospitalInfo} />
                                    <OperatorListTable operatorList={operatorInfo.data.operator} />
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OperatorSetting;
