import { useQuery } from "@tanstack/react-query";
import fetchData from "apis/fetchData";

const fetchCycleData = async (programId) => {
    return fetchData("/v1/management/ett/load/cycleInfo", { programId });
};

const useCycleData = (programId) => {
    return useQuery({
        queryKey: ["cycleData", programId],
        queryFn: () => fetchCycleData(programId),
        enabled: !!programId,
    });
};

export default useCycleData;
