import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import useCycleData from "../../hooks/counseling/useCycleData";
import PropTypes from "prop-types";

const CycleTab = ({ activeTab, handleTabClick, programId, type, onCycleDataChange }) => {
    const cycleTypes = [
        { id: "1", label: "1차 검사", dataKey: "cycleIdFirst" },
        { id: "2", label: "2차 검사", dataKey: "cycleIdSecond" },
        { id: "3", label: "3차 검사", dataKey: "cycleIdThird" },
    ];

    const { data: cycleData } = useCycleData(programId);
    const topInfo = cycleData?.data.topInfo;

    React.useEffect(() => {
        if (cycleData && typeof onCycleDataChange === "function") {
            onCycleDataChange(cycleData);
        }
    }, [cycleData, onCycleDataChange]);

    const renderTab = (cycle, index) => {
        const isActive = activeTab === cycle.id;
        const isModifyMode = type === "modify";
        const currentCycleId = topInfo?.[cycle.dataKey];
        const prevCycleId = index > 0 ? topInfo?.[cycleTypes[index - 1].dataKey] : null;

        // 해당 차수의 검사 ID가 있거나, modify 모드에서 이전 차수의 ID가 있는 경우 클릭 가능
        const isClickable = currentCycleId !== null || (isModifyMode && prevCycleId !== null);

        if (isClickable) {
            return (
                <Link className={`text-decoration-none ${isActive ? "" : "text-body"}`} onClick={() => handleTabClick(cycle.id, currentCycleId)}>
                    <div className="fw-bold">{cycle.label}</div>
                </Link>
            );
        } else {
            return <div className="fw-bold text-secondary-light">{cycle.label}</div>;
        }
    };

    return (
        <Row className="justify-content-md-start p-2">
            {cycleTypes.map((cycle, index) => (
                <Col key={cycle.id} xs lg={1} className="p-2 d-flex align-items-center justify-content-center">
                    {renderTab(cycle, index)}
                </Col>
            ))}
        </Row>
    );
};

CycleTab.propTypes = {
    activeTab: PropTypes.string.isRequired,
    handleTabClick: PropTypes.func.isRequired,
    programId: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    onCycleDataChange: PropTypes.func,
};

export default CycleTab;
