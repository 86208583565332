import App from "App";
import HosiptalList from "components/Management/HosiptalList";
import HosiptalRegister from "components/Management/HosiptalRegister";
import OperatorDetail from "components/OperatorSetting/OperatorDetail";
import OperatorRegister from "components/OperatorSetting/OperatorRegister";
import StaffRegister from "components/StaffSetting/StaffRegister";
import UserSetting from "pages/UserSetting";
import Layout from "pages/Layout";
import Login from "pages/Login";
import Monitoring from "pages/Monitoring";
import MonitoringDetail from "pages/MonitoringDetail";
import OperatorSetting from "pages/OperatorSetting";
import StaffSetting from "pages/StaffSetting";
import { RouterProvider, createBrowserRouter, Routes, Route } from "react-router-dom";
import Error404 from "../pages/error/Error404";
import StaffDetail from "components/StaffSetting/StaffDetail";
import CryptTest from "pages/CryptTest";
import ProtectedRoute from "./ProtectedRoute"; // ProtectedRoute import 추가

import MainLayoutProvider from "providers/MainLayoutProvider";
import MainLayout from "layouts/MainLayout";
import AuthSimpleLayout from "layouts/AuthSimpleLayout";

import EttList from "pages/patientMng/EttList";
import EttRegister from "pages/patientMng/EttRegister";
import EttModify from "pages/patientMng/EttModify";
import EttDetail from "pages/patientMng/EttDetail";

const Router = () => {
    const router = createBrowserRouter([
        {
            path: "/",
            element: <App />,
            children: [
                {
                    path: "/",
                    element: (
                        <AuthSimpleLayout>
                            <Login layout="simple" />
                        </AuthSimpleLayout>
                    ),
                },
                {
                    element: (
                        <ProtectedRoute>
                            <MainLayoutProvider>
                                {/* <Layout /> */}
                                <MainLayout />
                            </MainLayoutProvider>
                        </ProtectedRoute>
                    ),
                    children: [
                        {
                            path: "/crypt",
                            element: <CryptTest />,
                        },
                        {
                            path: "/patientMng/ett/list",
                            element: <EttList />,
                        },
                        {
                            path: "/patientMng/ett/register",
                            element: <EttRegister />,
                        },
                        {
                            path: "/patientMng/ett/modify",
                            element: <EttModify />,
                        },
                        {
                            path: "/patientMng/ett/detail",
                            element: <EttDetail />,
                        },
                        {
                            path: "/monitoring",
                            element: <Monitoring />,
                        },
                        {
                            path: "/monitoring/detail",
                            element: <MonitoringDetail />,
                        },
                        {
                            path: "/hospital/list",
                            element: <HosiptalList />,
                        },
                        {
                            path: "/hospital/register",
                            element: <HosiptalRegister />,
                        },
                        {
                            path: "/operator-setting",
                            element: <OperatorSetting />,
                        },
                        {
                            path: "/operator-setting/register",
                            element: <OperatorRegister />,
                        },
                        {
                            path: "/operator-setting/detail",
                            element: <OperatorDetail />,
                        },
                        {
                            path: "/staff-setting",
                            element: <StaffSetting />,
                        },
                        {
                            path: "/staff-setting/register",
                            element: <StaffRegister />,
                        },
                        {
                            path: "/staff-setting/detail",
                            element: <StaffDetail />,
                        },
                        {
                            path: "/user-setting",
                            element: <UserSetting />,
                        },

                        { path: "*", element: <Error404 /> }, // 404 라우트를 추가
                    ],
                },
            ],
        },
    ]);

    return <RouterProvider router={router} />;
};

export default Router;
