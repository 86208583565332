import usePatientInfo from "hooks/usePatientInfo";
import { Col, Row, Spinner } from "react-bootstrap";

const PatientInfoRow = (patientUID) => {
    const { data: patientInfo, isLoading, error } = usePatientInfo(patientUID);

    if (isLoading) {
        return (
            <Row className="p-2 mb-1">
                <Col className="p-2 border d-flex align-items-center justify-content-center">
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                </Col>
            </Row>
        );
    }

    if (error) {
        return (
            <Row className="p-2 mb-1">
                <Col className="p-2 border d-flex align-items-center justify-content-center">
                    <p>Error loading patient information</p>
                </Col>
            </Row>
        );
    }

    return (
        <Row className="p-3 mb-1">
            <Col className="p-2 border d-flex align-items-center justify-content-center bg-body-highlight" sm={2}>
                <p className="mb-0 text-body-tertiary fw-bold">환자명</p>
            </Col>
            <Col className="p-2 border d-flex align-items-center justify-content-center" sm={2}>
                <p className="mb-0 text-secondary-dark fw-bold">{patientInfo ? patientInfo.name : "-"}</p>
            </Col>
            <Col className="p-2 border d-flex align-items-center justify-content-center bg-body-highlight" sm={2}>
                <p className="mb-0 text-body-tertiary fw-bold">생년월일</p>
            </Col>
            <Col className="p-2 border d-flex align-items-center justify-content-center" sm={2}>
                <p className="mb-0 text-body-dark fw-bold">{patientInfo ? patientInfo.birth : "-"}</p>
            </Col>
            <Col className="p-2 border d-flex align-items-center justify-content-center bg-body-highlight" sm={2}>
                <p className="mb-0 text-body-tertiary fw-bold">핸드폰번호</p>
            </Col>
            <Col className="p-2 border d-flex align-items-start justify-content-center" sm={2}>
                <p className="mb-0 text-body-dark fw-bold">{patientInfo ? patientInfo.phone : "-"}</p>
            </Col>
        </Row>
    );
};

export default PatientInfoRow;
