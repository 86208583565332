import MuiDatePicker from "components/base/MuiDatePicker";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { currentDate } from "store/atoms";
import ComparativeAnalysis from "./ComparativeAnalysis";

const AnalysisResultContainer = () => {
    const [searchDate, setSearchDate] = useRecoilState(currentDate);
    const navigate = useNavigate();

    const handleClickDate = (e) => {
        setSearchDate(e.target.value);
    };

    return (
        <>
            <div className="w-50">
                <MuiDatePicker
                    onChange={(value) => {
                        handleClickDate(value);
                    }}
                    value={searchDate}
                />
            </div>
            <Row>
                <Col md={12}>
                    <ComparativeAnalysis />
                </Col>
            </Row>

            <Button className="w-100" variant="primary" onClick={() => navigate("/monitoring", { replace: true })}>
                목록
            </Button>
        </>
    );
};

export default AnalysisResultContainer;
