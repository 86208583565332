import React from "react";
import CounselingItem from "./CounselingItem";
import { Card } from "react-bootstrap";

const CounselingList = ({ data, type }) => {
    return (
        <Card className="mb-4">
            <Card.Body className="p-4">
                {data.counsel.map((item, index) => (
                    <React.Fragment key={item.id_}>
                        {index > 0 && (
                            <div className="d-flex justify-content-center">
                                <hr
                                    className="my-0"
                                    style={{
                                        width: "98%",
                                        borderStyle: "dashed",
                                        borderWidth: "1px",
                                        borderColor: "#dee2e6",
                                    }}
                                />
                            </div>
                        )}
                        <CounselingItem data={item} type={type} />
                    </React.Fragment>
                ))}
            </Card.Body>
        </Card>
    );
};

export default CounselingList;
