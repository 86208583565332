import React from "react";

const CounselingItem = ({ data, type }) => {
    const formatContent = (content) => {
        return content.split("\n").map((line, index) => (
            <React.Fragment key={index}>
                {line}
                <br />
            </React.Fragment>
        ));
    };
    return (
        <div className="p-4">
            <div className="d-flex mb-2">
                <h5>
                    {data.doctorName} {data.department !== null ? data.department : ""}
                </h5>
                <h5 className="ms-3">{data.createTime}</h5>
            </div>
            <div style={{ whiteSpace: "pre-wrap" }}>{formatContent(data.comments)}</div>
        </div>
    );
};

export default CounselingItem;
