import { useMutation } from "@tanstack/react-query";
import fetchData from "apis/fetchData";
import HospitalInfoTable from "components/Setting/HospitalInfoTable";
import StaffListTable from "components/StaffSetting/StaffListTable";
import { jwtDecode } from "jwt-decode";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Row } from "react-bootstrap";

const StaffSetting = () => {
    const navigate = useNavigate();
    const [role, setRole] = useState(null);
    const [uuid, setUuid] = useState(null);

    const token = sessionStorage.getItem("token");

    useEffect(() => {
        if (token) {
            const decoded = jwtDecode(token);
            setRole(decoded.role);
            setUuid(decoded.uuid);
        }
    }, [token]);

    const { data: staffInfo, mutate: staffInfoMutate } = useMutation({
        mutationFn: () => fetchData("/v1/setting/staff/list"),
    });

    useEffect(() => {
        if (token) {
            if (role === 2) navigate(`/staff-setting/detail?id=${uuid}`);
            else staffInfoMutate();
        }
    }, [token, navigate, role, uuid, staffInfoMutate]);

    return (
        <div className="pb-5">
            <Row className="g-4">
                <div className="mb-2">
                    <h2>실무자 관리</h2>
                </div>
                <hr className="bg-body-secondary mb-3" />
            </Row>

            <div className="order-1 order-xl-0 col-xl-12 col-12">
                <div className="mb-4 shadow-none border overflow-visible card">
                    <div className="p-0 card-body">
                        <div className="p-4">
                            {staffInfo && (
                                <>
                                    <HospitalInfoTable hospitalInfo={staffInfo.data.hospitalInfo} />
                                    <StaffListTable staffList={staffInfo.data.staff} />
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default StaffSetting;
