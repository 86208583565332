import Swal from "sweetalert2";
import { useAppContext } from "providers/AppProvider";

const useConfirmDialog = () => {
    const {
        config: { theme },
    } = useAppContext();

    const showConfirmDialog = (options = {}, onConfirm, onCancel) => {
        const {
            text = "진행하시겠습니까?",
            confirmButtonText = "확인",
            cancelButtonText = "취소",
            icon = "info",
            allowOutsideClick = false,
            timer,
        } = options;

        const swalOptions = {
            html: text.replace(/\n/g, "<br>"),
            icon, //success,info,warning,error,question
            allowOutsideClick,
            backdrop: !allowOutsideClick,
            confirmButtonText,
            showCancelButton: true,
            cancelButtonText,
            customClass: {
                confirmButton: "btn btn-primary",
                cancelButton: "btn bg-body-tertiary",
            },
            background: theme === "dark" ? "#1a1a1a" : "#fff",
            color: theme === "dark" ? "#fff" : "#545454",
        };

        // timer 값이 있을 때만 옵션에 추가
        if (timer !== undefined) {
            swalOptions.timer = timer;
        }

        Swal.fire(swalOptions).then((result) => {
            if (result.isConfirmed) {
                onConfirm && onConfirm();
            } else if (result.dismiss === Swal.DismissReason.cancel || result.dismiss === Swal.DismissReason.timer) {
                onCancel && onCancel();
            }
        });
    };

    return showConfirmDialog;
};

export default useConfirmDialog;
