import { instance } from "apis";

/**
 * 주어진 API 엔드포인트로 POST 요청을 보내 데이터를 가져옵니다.
 *
 * @async
 * @function fetchData
 * @param {string} endpoint - 요청을 보낼 API 엔드포인트.
 * @param {Object} requestData - POST 요청의 바디에 포함될 데이터.
 * @returns {Promise<Object>} - API로부터의 응답을 반환합니다.
 */
const fetchData = async (endpoint, requestData) => {
    const response = await instance.post(endpoint, requestData);
    return response;
};

export default fetchData;
