export const OPTION_LIST = [
    { no: 1, title: "급성 심근경색증, 불안정성 협심증으로 입원치료(약물치료, 관상동맥중재술 등)를 받은 환자" },
    { no: 2, title: "관상동맥우회로이식, 심장판막 수술, 심장이식 등의 심장수술을 받은 환자" },
    { no: 3, title: "보상된 심부전, 진행성 심부전" },
    {
        no: 4,
        title: "심박동기, 삽입형제세동기, 심장재동기화치료기(cardiac resynchronization therapy, CRT), 좌심실보조장치(left ventricular assistive device, LVAD) 삽입 수술을 받은 환자",
    },
    { no: 5, title: "약물로 조절되는 심방-심실성 부정맥, 심실세동-심정지 경험자" },
    { no: 6, title: "말초동맥질환(하지동맥, 대동맥류 및 박리 등)으로 중재술이나 수술을 받은 환자" },
    { no: 7, title: "심혈관질환 고위험군에 속하나 여러가지 이유로 적정 수준의 운동을 하기 어려운 환자" },
    { no: 8, title: "선천성 심장병으로 수술 및 약물치료를 받은 환자" },
    { no: 9, title: "우심실 부전이 예상되는 주요 폐 수술(폐전적출술, 폐엽절제술)을 시행한 환자" },
];
