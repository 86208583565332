import { useQuery } from "@tanstack/react-query";
import fetchData from "apis/fetchData";
import { toast } from "sonner";

const useCounselingList = (targetId, programId, type, etc) => {
    return useQuery({
        queryKey: ["counselingList", targetId, programId, type, etc],
        queryFn: () => fetchData("/v1/management/load/counsel", { targetId, programId, type, etc }),
        onSuccess: (data) => {
            console.log("Counseling list fetched:", data);
        },
        onError: (error) => {
            console.error("Error fetching counseling list:", error);
            toast.error("상담 내역을 불러오는데 실패했습니다.");
        },
    });
};

export default useCounselingList;
