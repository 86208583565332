import { useEffect, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import getUserRoleLevel from "./getUserRoleLevel";
import { routes } from "../sitemap";
import { toast } from "sonner";

//sitemap.jsx에 메뉴접근 권한 체크
//
export const useUrlChangeEffect = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const checkUserAccess = useCallback((path) => {
        // 로그인 페이지('/')는 항상 접근 가능
        if (path === "/") {
            return true;
        }

        // 사용자 권한 조회
        const userRole = getUserRoleLevel();

        // 경로 구조 평탄화
        const flattenedRoutes = routes.flatMap((category) => category.pages.flatMap((page) => (page.pages ? [page, ...page.pages] : page)));

        // 경로패턴 속성을 사용하여 일치하는 경로 찾기
        const matchedRoute = flattenedRoutes.find((route) => {
            if (route.path === path) return true;
            if (route.pathPattern) {
                const pattern = new RegExp("^" + route.pathPattern.replace(/\*/g, "[^?]*") + "($|\\?)");
                return pattern.test(path);
            }
            return false;
        });

        if (matchedRoute) {
            if (matchedRoute.roleLvl) {
                return matchedRoute.roleLvl.includes(userRole);
            }
            // roleLvl이 정의되지 않은 경우 기본적으로 액세스 허용
            return true;
        }

        // 일치하는 경로를 찾을 수 없는 경우 액세스 거부
        return false;
    }, []);

    //url 변경시(IN/OUT) 이벤트 처리 관리하는곳, 권한체크도 함께
    const handleUrlChange = useCallback(() => {
        const currentPath = location.pathname + location.search;
        const previousPath = sessionStorage.getItem("previousPath");

        if (previousPath !== currentPath) {
            if (!checkUserAccess(currentPath)) {
                //console.log("접근 권한이 없습니다.");
                navigate(previousPath || "/"); // 이전 페이지 또는 홈으로 리다이렉트
                return;
            }

            if (!previousPath?.startsWith("/patientMng/ett/") && currentPath.startsWith("/patientMng/ett/")) {
                // console.log("ett 영역 진입");
            }

            if (previousPath?.startsWith("/patientMng/ett/") && !currentPath.startsWith("/patientMng/ett/")) {
                // console.log("ett 영역 이탈");
                sessionStorage.removeItem("patientUID");
            }

            if (previousPath?.startsWith("/monitoring") && !currentPath.startsWith("/monitoring")) {
                // console.log("monitoring 영역 이탈");
                sessionStorage.removeItem("searchValue");
                sessionStorage.removeItem("searchResult");
            }
        }
    }, [location.pathname]);

    // URL 변경 감지
    useEffect(() => {
        handleUrlChange();
    }, [handleUrlChange]);

    // 브라우저 뒤로가기/앞으로가기 처리
    useEffect(() => {
        // 페이지 이동 감지 함수 등록
        window.addEventListener("popstate", handleUrlChange);
        // 컴포넌트가 화면에서 사라질 때 실행될 정리 함수
        return () => {
            // 등록한 감지 함수 제거
            window.removeEventListener("popstate", handleUrlChange);
        };
    }, [handleUrlChange]);
};
