import { Modal } from "react-bootstrap";
import CounselingModalForm from "./CounselingModalForm";

const CounselingModalFormIndex = ({ show, handleClose, targetId, programId, type, etc }) => {
    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>상담 내용 작성</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <CounselingModalForm handleClose={handleClose} targetId={targetId} programId={programId} type={type} etc={etc} />
            </Modal.Body>
        </Modal>
    );
};

export default CounselingModalFormIndex;
