//메뉴별 권한 레벨 체크 추가
export const routes = [
    {
        label: "patientMng",
        active: true,
        labelDisabled: true,
        hasNew: false,
        pages: [
            {
                name: "환자 관리", // 메뉴명
                icon: "user-check", // 메뉴 아이콘
                path: "/patientMng/ett/list", // 메뉴 경로
                pathPattern: "/patientMng/ett/*", // 사용자 url 접근권한 경로
                pathName: "patientMng",
                active: true,
                flat: true,
                roleLvl: [1, 2], // 접근가능 권한
            },
        ],
    },
    {
        label: "monitoring",
        active: true,
        labelDisabled: true,
        hasNew: false,
        pages: [
            {
                name: "환자 모니터링",
                icon: "pie-chart",
                path: "/monitoring",
                pathPattern: "/monitoring/*", // 사용자 url 접근권한 경로
                pathName: "monitoring",
                active: true,
                flat: true,
                roleLvl: [1, 2],
                // pages: [
                //     {
                //         name: "e-commerce",
                //         path: "/",
                //         pathName: "default-dashboard",
                //         topNavIcon: "shopping-cart",
                //         active: true,
                //     },
                // ],
            },
        ],
    },
    {
        label: "settings",
        active: true,
        labelDisabled: true,
        hasNew: false,
        pages: [
            {
                name: "설정 관리",
                icon: "settings",
                pathName: "settings",
                active: true,
                flat: true,
                dropdownInside: true,
                pages: [
                    {
                        name: "운영자 관리",
                        path: "/operator-setting",
                        pathPattern: "/operator-setting/*", // 사용자 url 접근권한 경로
                        roleLvl: [0, 1],
                        pathName: "settings-operator-setting",
                        topNavIcon: "user",
                        active: true,
                        flat: true,
                    },
                    {
                        name: "실무자 관리",
                        path: "/staff-setting",
                        pathPattern: "/staff-setting/*", // 사용자 url 접근권한 경로
                        pathName: "settings-staff-setting",
                        topNavIcon: "users",
                        active: true,
                        flat: true,
                    },
                    {
                        name: "환자 관리(임시)",
                        path: "/user-setting",
                        pathName: "settings-user-settingg",
                        topNavIcon: "users",
                        active: true,
                        flat: true,
                        roleLvl: [0],
                    },
                ],
            },
        ],
    },
];
