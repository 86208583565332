import { useState } from "react";
import { Button as BootstrapButton, Button, Form, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const OperatorListTable = ({ operatorList }) => {
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredOperators, setFilteredOperators] = useState(operatorList);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleSearchSubmit = (e) => {
        e.preventDefault();
        const lowerCaseSearchTerm = searchTerm.toLowerCase();
        const filtered = operatorList.filter(({ department, doctorName, userId, phone, eMail, comments }) =>
            `${department} ${doctorName} ${userId} ${phone} ${eMail} ${comments}`.toLowerCase().includes(lowerCaseSearchTerm),
        );
        setFilteredOperators(filtered);
    };

    return (
        <div className="mt-6">
            <h3 className="mb-2">운영자</h3>
            <Form onSubmit={handleSearchSubmit} className="d-flex mb-3">
                <Form.Control
                    type="text"
                    placeholder="검색어를 입력해주세요"
                    maxLength={50}
                    value={searchTerm}
                    onChange={handleSearchChange}
                    className="me-2 w-50"
                />
                <Button type="submit">검색</Button>
            </Form>

            <div className="scrollbar ms-n1 ps-1">
                <Table bordered hover className="phoenix-table fs-9 mb-0 border-top">
                    <thead className="bg-body-highlight">
                        <tr>
                            <th className="text-center text-body-tertiary" style={{ width: "6%", minWidth: "30px" }}>
                                NO
                            </th>
                            <th className="text-center text-body-tertiary" style={{ width: "15%", minWidth: "100px" }}>
                                부서
                            </th>
                            <th className="text-center text-body-tertiary" style={{ width: "15%", minWidth: "100px" }}>
                                의사
                            </th>
                            <th className="text-center text-body-tertiary" style={{ width: "15%", minWidth: "100px" }}>
                                아이디
                            </th>
                            <th className="text-center text-body-tertiary" style={{ width: "15%", minWidth: "100px" }}>
                                연락처
                            </th>
                            <th className="text-center text-body-tertiary" style={{ width: "15%", minWidth: "100px" }}>
                                이메일
                            </th>
                            <th className="text-center text-body-tertiary" style={{ width: "15%", minWidth: "100px" }}>
                                비고
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {(filteredOperators?.length ?? 0) > 0 ? (
                            filteredOperators.map(({ department, doctorName, userId, phone, eMail, comments, UUID }, index) => (
                                <tr key={UUID} onClick={() => navigate(`/operator-setting/detail?id=${UUID}`)} style={{ cursor: "pointer" }}>
                                    <td className="text-center">{index + 1}</td>
                                    <td className="text-center">{department ? department : "-"}</td>
                                    <td className="text-center" style={{ fontWeight: "bold" }}>
                                        {doctorName ? doctorName : "-"}
                                    </td>
                                    <td className="text-center">{userId}</td>
                                    <td className="text-center">{phone}</td>
                                    <td className="text-center">{eMail ? eMail : "-"}</td>
                                    <td className="text-center">{comments ? comments : "-"}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="7" className="text-center">
                                    조회된 검색결과가 없습니다
                                </td>
                            </tr>
                        )}
                    </tbody>
                </Table>
            </div>

            <div className="d-flex justify-content-end mt-3">
                <BootstrapButton variant="primary" onClick={() => navigate("/operator-setting/register")}>
                    등록하기
                </BootstrapButton>
            </div>
        </div>
    );
};

export default OperatorListTable;
