import { useQuery } from "@tanstack/react-query";
import fetchData from "apis/fetchData";

const fetchDoctors = () => {
    return fetchData("/v1/management/ett/regist/doctor/list");
};

export const useDoctors = () => {
    return useQuery({
        queryKey: ["doctors"],
        queryFn: fetchDoctors,
        enabled: true,
        staleTime: 5 * 60 * 1000, // 5분
        cacheTime: 10 * 60 * 1000, // 10분
    });
};
