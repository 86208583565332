import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "sonner";
import fetchData from "apis/fetchData";

const useCounselingForm = () => {
    const queryClient = useQueryClient();

    return useMutation({
        mutationFn: (requestData) => fetchData("/v1/management/regist/counsel", requestData),
        onSuccess: (data) => {
            queryClient.invalidateQueries({ queryKey: ["counselingList"] });
            toast.success("상담 내용이 저장되었습니다.");
        },
        onError: (error) => {
            console.error("Error submitting counseling:", error);
            toast.error("상담 내용 저장에 실패했습니다.");
        },
    });
};

export default useCounselingForm;
