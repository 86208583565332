import { useQuery } from "@tanstack/react-query";
import fetchData from "apis/fetchData";

//환자 정보 조회
const usePatientInfo = () => {
    const patientUID = sessionStorage.getItem("patientUID");

    return useQuery({
        queryKey: ["patientInfo", patientUID],
        queryFn: () => fetchData("/v1/management/ett/top/patient", { patientUID }),
        enabled: !!patientUID,
        select: (data) => data.data.patientInfo,
    });
};

export default usePatientInfo;
