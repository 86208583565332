import { useMutation } from "@tanstack/react-query";
import fetchData from "apis/fetchData";
import MuiDatePicker from "components/base/MuiDatePicker";
import { OPTION_LIST } from "constants/cardiacTargetTypeList";
import { TODAY } from "constants/monitoring";
import useConfirmDialog from "hooks/useConfirmDialog";
import { jwtDecode } from "jwt-decode";
import { useCallback, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "sonner";
import CycleTab from "../../components/PatientMng/CycleTab";
import PatientInfoRow from "../../components/PatientMng/PatientInfoRow";
import { useDoctors } from "../../hooks/counseling/useDoctors";
import { useEttData } from "../../hooks/counseling/useEttData";

// ETT정보 수정 : 2/3차
const EttModify = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    // URL 파라미터에서 데이터 가져오기
    const cycle = searchParams.get("cycle") || "1";
    const [activeTab, setActiveTab] = useState(cycle);
    const programId = searchParams.get("programId") || "";
    const patientUID = sessionStorage.getItem("patientUID");
    const token = sessionStorage.getItem("token");
    const { uuid } = jwtDecode(token);
    const [doctorsList, setDoctorsList] = useState(null);

    const [validated, setValidated] = useState(false);
    const [cycleInfo, setCycleInfo] = useState(null);
    const [isNewRegistration, setIsNewRegistration] = useState(true); // 데이터 등록/수정 분기처리용
    const [isLimitButton, setIsLimitButton] = useState(false);
    const [reDateBase, setReDateBase] = useState(false);

    const showConfirmDialog = useConfirmDialog();

    const formRef = useRef(null);
    const dateInspectFirstRef = useRef(null);
    const dateInspectSecondRef = useRef(null);
    const dateInspectThirdRef = useRef(null);
    const dateBaseRef = useRef(null);
    const dateOccurRef = useRef(null);

    const { data: doctors, isLoading: isDoctorsLoading, error: doctorsError } = useDoctors(); // 담당의 목록 조회
    const { data: ettDataResponse, isLoading: isEttDataLoading, error: ettDataError } = useEttData(cycle, programId); // ett 정보 조회

    const ettData = ettDataResponse?.data; // ettData에서 data 객체 추출

    const [formData, setFormData] = useState({
        programId: programId,
        patientUID: patientUID,
        doctorId: "", // 담당의 고유키
        reDateBase: null,
        height: "", // 키
        weight: "", // 몸무게
        diseaseName: "", // 질환명
        targetCategoryNo: "", // 대상구분 번호
        targetCategoryComments: "", // 대상구분 comments
        dateBase: "", // 기준일(퇴원일)
        dateOccur: "", // 발병일(진단일)
        dateInspectFirst: "", // 1차 검사일
        dateInspectSecond: "", // 2차 검사일
        dateInspectThird: "", // 3차 검사일
        restingHeartRate: "", // 안정시 심박수
        maxHeartRate: "", // 최대 심박수
        riskGroup: "", // 위험군
        METs: "", // 대사당량
        etcYn: false, // 기타질병 여부
        etc: "", // 기타질병명
        firstRegister: "",
        firstRegistDate: "",
        cycleId: "",
    });

    // URL 파라미터에 cycle 값이 변경될때 텝메뉴 활성화
    useEffect(() => {
        const currentCycle = searchParams.get("cycle") || "1";
        setActiveTab(currentCycle);
    }, [searchParams]);

    // 담당의 목록 세팅
    useEffect(() => {
        if (doctors?.data) {
            setDoctorsList(doctors.data.list);
        }
    }, [doctors]);

    useEffect(() => {
        if (ettData) {
            // 서버에서 받아온 데이터로 formData 업데이트
            setFormData((prevData) => ({
                ...prevData,
                programId: programId,
                patientUID: patientUID,
                doctorId: ettData.info?.doctorId || "",
                height: ettData.info?.height || "",
                weight: ettData.info?.weight || "",
                diseaseName: ettData.diseaseInfo?.diseaseName || "",
                targetCategoryNo: ettData.diseaseInfo?.targetCategoryNo,
                targetCategoryComments: ettData.diseaseInfo?.targetCategoryComments || "",
                dateBase: ettData.diseaseInfo?.dateBase || "",
                dateOccur: ettData.diseaseInfo?.dateOccur || "",
                dateInspectFirst: ettData.inspectInfo?.dateInspectFirst || "",
                dateInspectSecond: ettData.inspectInfo?.dateInspectSecond || "",
                dateInspectThird: ettData.inspectInfo?.dateInspectThird || "",
                restingHeartRate: ettData.inspectInfo?.restingHeartRate || "",
                maxHeartRate: ettData.inspectInfo?.maxHeartRate || "",
                riskGroup: ettData.inspectInfo?.riskGroup?.toString() || "1",
                METs: ettData.inspectInfo?.METs || "",
                etcYn: !!ettData.inspectInfo?.etc,
                etc: ettData.inspectInfo?.etc || "",
                firstRegister: ettData.firstRegister,
                firstRegistDate: ettData.firstRegistDate,
                cycleId: ettData.cycleId,
            }));
            setReDateBase(ettData.diseaseInfo?.reDateBase);
        }
    }, [ettData]);

    // 현재 활성화된 탭에 해당하는 cycleId가 null인 경우 신규 등록
    useEffect(() => {
        if (cycleInfo) {
            const currentCycleId = cycleInfo.data.topInfo[`cycleId${activeTab === "1" ? "First" : activeTab === "2" ? "Second" : "Third"}`];
            setIsNewRegistration(currentCycleId === null);
        }
    }, [cycleInfo, activeTab]);

    const handleCycleDataChange = useCallback((newCycleData) => {
        setCycleInfo(newCycleData);
    }, []);

    // n차검사 탭메뉴 클릭
    const handleTabClick = (tabNumber) => {
        setActiveTab(tabNumber);
        navigate(`/patientMng/ett/modify?cycle=${tabNumber}&programId=${programId}`, { replace: true });
    };

    // 텍스트필드 입력 핸들러
    const handleInputChange = (e) => {
        const { name, value } = e.target;

        if (name === "etcYn") {
            const boolValue = value === "true";
            setFormData((prev) => ({
                ...prev,
                [name]: boolValue,
            }));
        } else {
            // 숫자만 허용하는 필드명과 각 필드의 최대 길이 설정
            const numericFields = {
                height: 3,
                weight: 3,
                restingHeartRate: 3,
                maxHeartRate: 3,
            };

            if (name in numericFields) {
                // 숫자가 아닌 문자 제거 및 길이 제한
                const numericValue = value.replace(/[^0-9]/g, "").slice(0, numericFields[name]);
                setFormData((prev) => ({ ...prev, [name]: numericValue }));
            } else {
                setFormData((prev) => ({ ...prev, [name]: value }));
            }
        }
    };

    // 날짜필드 입력 핸들러
    const handleDateChange = (name, value) => {
        setFormData((prev) => ({ ...prev, [name]: value || "" }));
    };

    // 등록한지 24시간 지났는지 여부 확인
    useEffect(() => {
        if (formData.firstRegistDate) {
            const registDate = new Date(formData.firstRegistDate);
            const now = new Date();
            const diffHours = (now - registDate) / (1000 * 60 * 60);
            setIsLimitButton(diffHours <= 24);
        }
    }, [formData.firstRegistDate]);

    const handleSubmit = (e) => {
        e.preventDefault();
        const form = formRef.current;

        setValidated(true); // 폼 제출 시 validated를 true로 설정

        if (
            form.checkValidity() === false ||
            !formData.targetCategoryNo ||
            !formData.dateInspectFirst ||
            !formData.dateInspectSecond ||
            !formData.dateInspectThird
        ) {
            e.stopPropagation();

            // 먼저 일반적인 입력 필드의 유효성을 검사
            const firstInvalidInput = form.querySelector(":invalid");
            if (firstInvalidInput) {
                firstInvalidInput.focus();
                return;
            }

            // 날짜 필드 체크
            if (!formData.targetCategoryNo) {
                toast.error("대상구분을 선택해 주세요");
            } else if (!formData.dateBase && dateBaseRef.current) {
                dateBaseRef.current.focus();
            } else if (!formData.dateOccur && dateOccurRef.current) {
                dateOccurRef.current.focus();
            } else if (!formData.dateInspectFirst && dateInspectFirstRef.current) {
                dateInspectFirstRef.current.focus();
            } else if (!formData.dateInspectSecond && dateInspectSecondRef.current) {
                dateInspectSecondRef.current.focus();
            } else if (!formData.dateInspectThird && dateInspectThirdRef.current) {
                dateInspectThirdRef.current.focus();
            }

            return; // 유효성 검사 실패 시 여기서 함수 종료
        }

        //etcYn Y/N에 따라 서버에 전송할때 값 변경
        const submissionData = {
            ...formData,
            etc: formData.etcYn === true ? formData.etc : "",
        };

        const actionText = isNewRegistration ? "등록" : "수정";
        showConfirmDialog({ text: `${actionText}하시겠습니까?`, icon: "question" }, () => submitEttData({ ...submissionData }));
    };

    const { mutate: submitEttData, isLoading: isSubmitting } = useMutation({
        mutationFn: (data) => {
            const url = isNewRegistration ? "/v1/management/ett/regist" : "/v1/management/ett/modify/cycle";
            return fetchData(url, data);
        },
        onSuccess: () => {
            const actionText = isNewRegistration ? "등록" : "수정";
            toast.success(`ETT 데이터가 성공적으로 ${actionText}되었습니다.`);
            showConfirmDialog({ text: "조회 화면으로 이동하시겠습니까?", icon: "question" }, () =>
                navigate(`/patientMng/ett/detail?cycle=${cycle}&programId=${programId}`, { replace: true }),
            );
        },
        onError: (error) => {
            console.error("Error submitting ETT data:", error);
            const actionText = isNewRegistration ? "등록" : "수정";
            toast.error(`ETT 데이터 ${actionText}에 실패했습니다.`);
        },
    });

    if (isEttDataLoading)
        return (
            <div className="pb-5">
                <Row className="g-4">
                    <div className="mb-2">
                        <h2>ETT 기록</h2>
                    </div>
                    <p className="text-body-tertiary lead">운동부하 검사 수정</p>
                    <hr className="bg-body-secondary mb-3" />
                </Row>
                <div className="p-3 rounded-1 position-absolute top-50 start-50 translate-middle">
                    <div>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                </div>
            </div>
        );
    if (ettDataError) {
        return <div>에러 발생: {ettDataError.message}</div>;
    }

    return (
        <>
            <div className="pb-5">
                <Row className="g-4">
                    <div className="mb-2">
                        <h2>ETT 기록</h2>
                    </div>
                    <p className="text-body-tertiary lead">
                        운동부하 {cycle}차 검사 {isNewRegistration ? "등록" : "수정"}
                        {reDateBase === true ? <span className="text-danger">(재발병)</span> : ""}
                    </p>
                    <hr className="bg-body-secondary mb-3" />
                </Row>

                <div className="order-1 order-xl-0 col-xl-12 col-12">
                    <div className="mb-4 shadow-none border overflow-visible card">
                        <div className="p-0 card-body">
                            <div className="p-4">
                                <PatientInfoRow />

                                <CycleTab
                                    activeTab={activeTab}
                                    handleTabClick={handleTabClick}
                                    programId={programId}
                                    type="modify"
                                    onCycleDataChange={handleCycleDataChange}
                                />

                                <Form noValidate validated={validated} onSubmit={handleSubmit} ref={formRef}>
                                    <div className="mt-3">
                                        <h4>기본정보</h4>
                                    </div>

                                    <Row className="justify-content-md-start p-2">
                                        <Col xs lg={3} className="p-2">
                                            <Form.Group controlId="formName">
                                                <Form.Label className="p-0">
                                                    <h5>
                                                        담당의사 <span className="text-danger">*</span>
                                                    </h5>
                                                </Form.Label>
                                                <Form.Select name="doctorId" onChange={handleInputChange} value={formData.doctorId} required>
                                                    <option value="">선택하세요</option>
                                                    {isDoctorsLoading ? (
                                                        <option>로딩 중...</option>
                                                    ) : doctorsError ? (
                                                        <option>에러 발생</option>
                                                    ) : doctorsList && doctorsList.length > 0 ? (
                                                        doctorsList.map((item, index) => (
                                                            <option key={index} value={item.doctorId}>
                                                                {item.department !== "" && item.department !== null ? `[${item.department}] ` : ""}
                                                                {item.name} ({item.userId})
                                                            </option>
                                                        ))
                                                    ) : (
                                                        <option>의사 목록이 없습니다</option>
                                                    )}
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">담당의를 선택해주세요.</Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className="justify-content-md-start p-2">
                                        <Col xs lg={6} className="p-2">
                                            <Form.Group controlId="cm">
                                                <Form.Label className="p-0">
                                                    <h5>
                                                        키(cm) <span className="text-danger">*</span>
                                                    </h5>
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="height"
                                                    value={formData.height}
                                                    onChange={handleInputChange}
                                                    placeholder="ex) 180 (숫자만 입력하세요.)"
                                                    required
                                                    autoComplete="off"
                                                    pattern="\d*"
                                                />
                                                <Form.Control.Feedback type="invalid">필수 입력입니다.</Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col xs lg={6} className="p-2">
                                            <Form.Group controlId="kg">
                                                <Form.Label className="p-0">
                                                    <h5>
                                                        몸무게(kg) <span className="text-danger">*</span>
                                                    </h5>
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="weight"
                                                    value={formData.weight}
                                                    onChange={handleInputChange}
                                                    placeholder="ex) 70 (숫자만 입력하세요.)"
                                                    required
                                                    autoComplete="off"
                                                    pattern="\d*"
                                                />
                                                <Form.Control.Feedback type="invalid">필수 입력입니다.</Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <div className="mt-2">
                                        <h4>질환정보</h4>
                                    </div>

                                    <Row className="justify-content-md-start p-2">
                                        <Col sm={6}>
                                            <Row>
                                                <Col sm={3} className="text-muted">
                                                    <h5 className="text-body-quaternary mb-0">재발병 여부</h5>
                                                </Col>
                                                <Col sm={9}>
                                                    <h5 className="ms-4 mb-0">{reDateBase === true ? "해당있음" : "해당없음"}</h5>
                                                </Col>
                                                <hr
                                                    className="ms-3 mt-3"
                                                    style={{ borderStyle: "dashed", borderWidth: "1px 0 0 0 ", width: "95%" }}
                                                />
                                            </Row>
                                        </Col>

                                        {/* <Col xs lg={6} className="p-2">
                                            <Form.Group>
                                                <Form.Label className="p-0 mb-1">
                                                    <h5>
                                                        재발병 여부 <span className="text-danger">*</span>
                                                    </h5>
                                                </Form.Label>
                                            </Form.Group>
                                            <Form.Check
                                                inline
                                                type="radio"
                                                id="re_N"
                                                label="해당없음"
                                                name="reDateBase"
                                                value={false}
                                                checked={formData.reDateBase === false}
                                                onChange={handleInputChange}
                                            />
                                            <Form.Check
                                                inline
                                                type="radio"
                                                id="re_Y"
                                                label="해당있음"
                                                name="reDateBase"
                                                value={true}
                                                checked={formData.reDateBase === true}
                                                onChange={handleInputChange}
                                            /> */}
                                        {/* <Form.Group controlId="illness" className="mt-2">
                                                <Form.Label className="p-0">
                                                    <h5>
                                                        질환명 <span className="text-danger">*</span>
                                                    </h5>
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="diseaseName"
                                                    value={formData.diseaseName}
                                                    onChange={handleInputChange}
                                                    placeholder="ex) 고혈압, 당뇨병 등"
                                                    required
                                                    autoComplete="off"
                                                    maxLength={100}
                                                />
                                                <Form.Control.Feedback type="invalid">필수 입력입니다.</Form.Control.Feedback>
                                            </Form.Group> 
                                        </Col>*/}
                                    </Row>

                                    <Row className="justify-content-md-start p-2">
                                        <Col sm={6}>
                                            <Row>
                                                <Col sm={3} className="text-muted">
                                                    <h5 className="text-body-quaternary mb-0">질환명</h5>
                                                </Col>
                                                <Col sm={9}>
                                                    <h5 className="ms-4 mb-0">{formData.diseaseName !== "" ? formData.diseaseName : "-"}</h5>
                                                </Col>
                                                <hr
                                                    className="ms-4 mt-3"
                                                    style={{ borderStyle: "dashed", borderWidth: "1px 0 0 0 ", width: "95%" }}
                                                />
                                            </Row>
                                        </Col>
                                        <Col sm={6}>
                                            <Row>
                                                <Col sm={3} className="text-muted">
                                                    <h5 className="text-body-quaternary mb-0">대상구분</h5>
                                                </Col>
                                                <Col sm={9}>
                                                    <h5 className="mb-0">
                                                        {OPTION_LIST.find((option) => option.no === formData.targetCategoryNo)?.title}
                                                    </h5>
                                                </Col>
                                                <hr
                                                    className="ms-3 mt-3"
                                                    style={{ borderStyle: "dashed", borderWidth: "1px 0 0 0 ", width: "95%" }}
                                                />
                                            </Row>
                                        </Col>
                                    </Row>

                                    {/* <div className="mt-2">
                                        <h5>
                                            대상구분 <span className="text-danger">*</span>
                                        </h5>
                                    </div>
                                    {validated && !formData.targetCategoryNo && (
                                        <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
                                            대상구분을 선택해주세요.
                                        </Form.Control.Feedback>
                                    )}

                                    <ListGroup variant="flush" className=" overflow-y-auto" style={{ height: "270px" }}>
                                        <ListGroup.Item>
                                            <Row className="p-2 mb-2 align-middle" style={{ height: "45px" }}>
                                                {OPTION_LIST.map((option) => (
                                                    <Col sm={6} className="p-2" key={option.no}>
                                                        <Form.Check
                                                            type="radio"
                                                            id={`radio-${option.no}`}
                                                            label={`${option.no}. ${option.title}`}
                                                            name="targetCategoryNo"
                                                            value={option.no.toString()}
                                                            checked={formData.targetCategoryNo === option.no.toString()}
                                                            onChange={handleInputChange}
                                                            required
                                                        />
                                                    </Col>
                                                ))}
                                            </Row>
                                        </ListGroup.Item>
                                    </ListGroup> */}

                                    {/* <Row className="justify-content-md-start p-2">
                                        <Col xs lg={6} className="p-2">
                                            <div className="mt-2">
                                                <h5>
                                                    발병일(진단일) <span className="text-danger">*</span>
                                                </h5>
                                            </div>
                                            <MuiDatePicker
                                                maxDate={TODAY}
                                                value={formData.dateOccur}
                                                isInvalid={validated && !formData.dateOccur}
                                                onChange={(value) => handleDateChange("dateOccur", value)}
                                                ref={dateOccurRef}
                                            />
                                            {validated && !formData.dateOccur && (
                                                <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
                                                    필수 입력입니다.
                                                </Form.Control.Feedback>
                                            )}
                                        </Col>
                                        <Col xs lg={6} className="p-2">
                                            <div className="mt-2">
                                                <h5>
                                                    기준일(퇴원일) <span className="text-danger">*</span>
                                                </h5>
                                            </div>

                                            <MuiDatePicker
                                                maxDate={TODAY}
                                                value={formData.dateBase}
                                                isInvalid={validated && !formData.dateBase}
                                                onChange={(value) => handleDateChange("dateBase", value)}
                                                ref={dateBaseRef}
                                            />
                                            {validated && !formData.dateBase && (
                                                <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
                                                    필수 입력입니다.
                                                </Form.Control.Feedback>
                                            )}
                                        </Col>
                                    </Row> */}

                                    <Row className="justify-content-md-start p-2">
                                        <Col sm={6}>
                                            <Row>
                                                <Col sm={3} className="text-muted">
                                                    <h5 className="text-body-quaternary mb-0">발병일(진단일)</h5>
                                                </Col>
                                                <Col sm={9}>
                                                    <h5 className="mb-0">{formData.dateOccur}</h5>
                                                </Col>
                                                <hr
                                                    className="ms-3 mt-3"
                                                    style={{ borderStyle: "dashed", borderWidth: "1px 0 0 0 ", width: "95%" }}
                                                />
                                            </Row>
                                        </Col>
                                        <Col sm={6}>
                                            <Row>
                                                <Col sm={3} className="text-muted">
                                                    <h5 className="text-body-quaternary mb-0">기준일(퇴원일)</h5>
                                                </Col>
                                                <Col sm={9}>
                                                    <h5 className="ms-4 mb-0">{formData.dateBase}</h5>
                                                </Col>
                                                <hr
                                                    className="ms-4 mt-3"
                                                    style={{ borderStyle: "dashed", borderWidth: "1px 0 0 0 ", width: "95%" }}
                                                />
                                            </Row>
                                        </Col>
                                    </Row>

                                    <Row className="justify-content-md-start p-2">
                                        <Col xs lg={4} className="p-2">
                                            <div className="mt-2">
                                                <h5>
                                                    1차 검사일 <span className="text-danger">*</span>
                                                </h5>
                                            </div>
                                            <MuiDatePicker
                                                maxDate={TODAY}
                                                value={formData.dateInspectFirst || null}
                                                isInvalid={validated && !formData.dateInspectFirst}
                                                onChange={(value) => handleDateChange("dateInspectFirst", value)}
                                                ref={dateInspectFirstRef}
                                                disablProp={activeTab > 1}
                                            />
                                            {validated && !formData.dateInspectFirst && (
                                                <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
                                                    필수 입력입니다.
                                                </Form.Control.Feedback>
                                            )}
                                        </Col>

                                        <Col xs lg={4} className="p-2">
                                            <div className="mt-2">
                                                <h5>
                                                    2차 검사일 <span className="text-danger">*</span>
                                                </h5>
                                            </div>
                                            <MuiDatePicker
                                                value={formData.dateInspectSecond || null}
                                                isInvalid={validated && !formData.dateInspectSecond}
                                                onChange={(value) => handleDateChange("dateInspectSecond", value)}
                                                ref={dateInspectSecondRef}
                                                disablProp={activeTab > 2}
                                            />
                                            {validated && !formData.dateInspectSecond && (
                                                <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
                                                    필수 입력입니다.
                                                </Form.Control.Feedback>
                                            )}
                                        </Col>
                                        <Col xs lg={4} className="p-2">
                                            <div className="mt-2">
                                                <h5>
                                                    3차 검사일 <span className="text-danger">*</span>
                                                </h5>
                                            </div>
                                            <MuiDatePicker
                                                value={formData.dateInspectThird || null}
                                                isInvalid={validated && !formData.dateInspectThird}
                                                onChange={(value) => handleDateChange("dateInspectThird", value)}
                                                ref={dateInspectThirdRef}
                                            />
                                            {validated && !formData.dateInspectThird && (
                                                <Form.Control.Feedback type="invalid" style={{ display: "block" }}>
                                                    필수 입력입니다.
                                                </Form.Control.Feedback>
                                            )}
                                        </Col>
                                    </Row>

                                    <Row className="justify-content-md-start p-2">
                                        <Col xs lg={6} className="p-2">
                                            <Form.Group controlId="illness">
                                                <Form.Label className="p-0">
                                                    <h5>
                                                        안정 시 심박수(Resting HR) <span className="text-danger">*</span>
                                                    </h5>
                                                </Form.Label>

                                                <Form.Control
                                                    type="text"
                                                    name="restingHeartRate"
                                                    value={formData.restingHeartRate}
                                                    onChange={handleInputChange}
                                                    placeholder="ex) 89 (숫자만 입력하세요.)"
                                                    required
                                                    autoComplete="off"
                                                    pattern="\d*"
                                                />
                                                <Form.Control.Feedback type="invalid">필수 입력입니다.</Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                        <Col xs lg={6} className="p-2">
                                            <Form.Group controlId="illness">
                                                <Form.Label className="p-0">
                                                    <h5>
                                                        최대 심박수(Maximal HR) <span className="text-danger">*</span>
                                                    </h5>
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    name="maxHeartRate"
                                                    value={formData.maxHeartRate}
                                                    onChange={handleInputChange}
                                                    placeholder="ex) 110 (숫자만 입력하세요.)"
                                                    required
                                                    autoComplete="off"
                                                    pattern="\d*"
                                                />
                                                <Form.Control.Feedback type="invalid">필수 입력입니다.</Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className="justify-content-md-start p-2">
                                        <Col xs lg={6} className="p-2">
                                            <Form.Group>
                                                <Form.Label className="p-0 mb-1">
                                                    <h5>
                                                        위험군 <span className="text-danger">*</span>
                                                    </h5>
                                                </Form.Label>
                                            </Form.Group>
                                            <Form.Check
                                                inline
                                                type="radio"
                                                id="danger_1"
                                                label="저위험군"
                                                value="1"
                                                name="riskGroup"
                                                checked={formData.riskGroup === "1"}
                                                onChange={handleInputChange}
                                            />
                                            <Form.Check
                                                inline
                                                type="radio"
                                                id="danger_2"
                                                label="중위험군"
                                                value="2"
                                                name="riskGroup"
                                                checked={formData.riskGroup === "2"}
                                                onChange={handleInputChange}
                                            />
                                            <Form.Check
                                                inline
                                                type="radio"
                                                id="danger_3"
                                                label="고위험군"
                                                name="riskGroup"
                                                value="3"
                                                checked={formData.riskGroup === "3"}
                                                onChange={handleInputChange}
                                            />
                                        </Col>

                                        <Col xs lg={6} className="p-2">
                                            <Form.Group controlId="illness">
                                                <Form.Label className="p-0">
                                                    <h5>
                                                        METs <span className="text-danger">*</span>
                                                    </h5>
                                                </Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={formData.METs}
                                                    onChange={(e) => {
                                                        let value = e.target.value.replace(/[^0-9.]/g, "");
                                                        if (value.includes(".")) {
                                                            const parts = value.split(".");
                                                            value = `${parts[0]}.${parts[1].slice(0, 1)}`;
                                                        } else if (value.length > 2) {
                                                            value = `${value.slice(0, -1)}.${value.slice(-1)}`;
                                                        }
                                                        handleInputChange({
                                                            target: { name: "METs", value },
                                                        });
                                                    }}
                                                    placeholder="ex) 6.7 (숫자만 입력하세요.)"
                                                    required
                                                    autoComplete="off"
                                                />
                                                <Form.Control.Feedback type="invalid">필수 입력입니다.</Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className="justify-content-md-start p-2">
                                        <Col xs lg={6} className="p-2">
                                            <Form.Group>
                                                <Form.Label className="p-0 mb-1">
                                                    <h5>기타질병</h5>
                                                </Form.Label>
                                            </Form.Group>
                                            <Form.Check
                                                inline
                                                type="radio"
                                                id="other_N"
                                                label="없음"
                                                name="etcYn"
                                                value="false"
                                                checked={formData.etcYn === false}
                                                onChange={handleInputChange}
                                            />
                                            <Form.Check
                                                inline
                                                type="radio"
                                                id="other_Y"
                                                label="있음"
                                                name="etcYn"
                                                value="true"
                                                checked={formData.etcYn === true}
                                                onChange={handleInputChange}
                                            />
                                            <Form.Control
                                                type="text"
                                                name="etc"
                                                value={formData.etcYn ? formData.etc : ""}
                                                onChange={handleInputChange}
                                                placeholder="ex) 고혈압, 당뇨병 등"
                                                required={formData.etcYn}
                                                autoComplete="off"
                                                maxLength={100}
                                                disabled={!formData.etcYn}
                                            />
                                        </Col>
                                    </Row>

                                    <div className="mt-2 d-flex justify-content-end">
                                        {isNewRegistration === true ? (
                                            <>
                                                <Button
                                                    variant="secondary"
                                                    className="ms-2"
                                                    onClick={() =>
                                                        navigate(
                                                            `/patientMng/ett/detail?cycle=${
                                                                activeTab > 1 ? activeTab - 1 : 1
                                                            }&programId=${programId}`,
                                                            {
                                                                replace: true,
                                                            },
                                                        )
                                                    }
                                                >
                                                    취소
                                                </Button>

                                                <Button variant="primary" className="ms-2" type="submit" disabled={isSubmitting}>
                                                    {isSubmitting ? "저장 중..." : "등록하기"}
                                                </Button>
                                            </>
                                        ) : (
                                            <>
                                                <Button
                                                    variant="secondary"
                                                    className="ms-2"
                                                    onClick={() =>
                                                        navigate(`/patientMng/ett/detail?cycle=${activeTab}&programId=${programId}`, {
                                                            replace: true,
                                                        })
                                                    }
                                                >
                                                    취소
                                                </Button>
                                                {(uuid === formData.firstRegister || uuid === formData.doctorId) && isLimitButton && (
                                                    <Button variant="primary" className="ms-2" type="submit" disabled={isSubmitting}>
                                                        {isSubmitting ? "저장 중..." : "수정하기"}
                                                    </Button>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </Form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EttModify;
